import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_blue.css";

import { English } from "flatpickr/dist/l10n/default.js"
import { Greek } from "flatpickr/dist/l10n/gr.js"

export default class extends Flatpickr {

  locales = {
    gr: Greek,
    en: English
  };

  initialize() {
    this.config = {
      locale: "gr",
      dateFormat: "d-m-Y",
      allowInput: true, 
      minTime: "08:00",
      maxTime: "23:00",
      minuteIncrement: 15,
      time_24hr: true,
   //   defaultHour: new Date().getHours()
    };
  }

}
