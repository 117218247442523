import { Controller } from "@hotwired/stimulus"
// import hammer from 'hammerjs';

export default class extends Controller {

	static targets = ["area", "link"]
	static values = { page: String, extraParams: String, current: Number, total: Number }

	// connect() {

	// 	let page = this.pageValue.replace(".js","") + ".js?page="
	// 	let currentUrl = page + this.currentValue + this.extraParamsValue
	// 	let nextUrl = page + Math.min(this.totalValue, this.currentValue + 1) + this.extraParamsValue
	// 	let previousUrl = page + Math.max(1, this.currentValue - 1) + this.extraParamsValue

	// 	// Selection for Copy Paste shouldn't be a swipe
	// 	// delete Hammer.defaults.cssProps.userSelect;

	// 	// Swipe Right / Left
	// 	var hammertime = new Hammer(this.areaTarget, {
	// 		domEvents: true
	// 	});
	// 	hammertime.on('swipeleft', function(event) {
	// 		if (nextUrl != "" && nextUrl != currentUrl) {
	// 			$('.spinner').show()
	// 			$.ajax(nextUrl)
	// 			$('.spinner').hide()
	// 		}
	// 	});
	// 	hammertime.on('swiperight', function (event) {
	// 		if (previousUrl != "" && previousUrl != currentUrl) { 
	// 			$('.spinner').show()
	// 			$.ajax(previousUrl)
	// 			$('.spinner').hide()
	// 		}
			
	// 	});
	// };

	// For HP Pagination
	load_url(event) {
		event.preventDefault()
		$('.spinner').show()
		$.ajax({
			url: event.target.href + this.extraParamsValue,
			dataType: 'script'
		})
		$('.spinner').hide()
	};
} 