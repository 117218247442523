import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "avatars", "avatarInteractions", "avatarPaid" ]

    connect() {
        if (this.hasAvatarPaidTarget) {
            this.avatarsTarget.classList.add("cursor-pointer")
        }
    }

    toggleAvatar() {
        if (this.hasAvatarPaidTarget) {
            this.avatarInteractionsTarget.classList.toggle("hidden")
            this.avatarPaidTarget.classList.toggle("hidden")
        }
    }
}