import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {
    static targets = [ "card", "hideable" ]
    static values = { updateUrl: String, showPatient: String, isPatientPage: String }

	fieldUpdate(event) {
		let formData = new FormData()
		formData.append(event.currentTarget.dataset.fieldName, event.currentTarget.dataset.fieldValue);
		formData.append("task[show_patient]", this.showPatientValue)
		formData.append("task[is_patient_page]", this.isPatientPageValue)
		_paq.push(['trackEvent', 'Quick Form', 'Quick Form: task', "edit-" + event.currentTarget.dataset.fieldName, event.currentTarget.dataset.fieldValue]);
		patch(this.updateUrlValue, { body: formData, responseKind: "turbo-stream" })
	}

	toggleCard(event) {
		if (this.hasHideableTarget) {
			event.detail.value == "show" ? this.hideableTarget.classList.remove("hidden") : this.hideableTarget.classList.add("hidden")
		}
	}
}