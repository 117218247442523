import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select/dist/js/tom-select.popular.js';
// https://tom-select.js.org
// https://coolrequest.dev/2021/11/25/replace_select2.html

export default class extends Controller {

  static values = { options: String, optionsList: Array, itemsList: Array }

  connect() {
    if(this.optionsValue == "multiple") {
       new TomSelect(this.element, {
        hidePlaceholder: true,
        maxOptions: 100,
        closeAfterSelect: true,
        plugins: ['remove_button', 'no_backspace_delete'],
      })
    } else if(this.optionsValue == "multiple-tags") { // This is used in Patient Tags on Filterrific 
       new TomSelect(this.element, {
        hidePlaceholder: true,
        maxOptions: 100,
        closeAfterSelect: true,
        valueField: 'value',
        labelField: 'text',
        options: this.optionsListValue,
        items: this.itemsListValue,
        plugins: ['remove_button', 'no_backspace_delete'],
      }) 
    } else if(this.optionsValue == "multiple-create") { // This is used in Patient Tags on Create/Update Form
        new TomSelect(this.element, {
        create: true,
        createOnBlur: true,
        hidePlaceholder: true,
        closeAfterSelect: true,
        maxOptions: 100,
        plugins: ['remove_button', 'no_backspace_delete'],
      })
    } else if(this.optionsValue == "link_in") { // This is used for Select Dropdown links opening on same page
        new TomSelect(this.element, {
          hideSelected: false,
          onChange(value) {
              Turbo.visit(value, { frame: "_top" })
          }
      })
    // } else if(this.optionsValue == "link_out") { // This is used for Select Dropdown links opening in new tab
    //   new TomSelect(this.element, {
    //       onChange(value) {
    //           window.open(value)
    //       }
    //   })
    } else if(this.optionsValue == "change_patient") { // This is used for Select Dropdown links opening in new tab
      new TomSelect(this.element, {
        hidePlaceholder: false,
        openOnFocus: false
      })
    } else {
      new TomSelect(this.element)
    }
  }

}
 