import { Controller } from "@hotwired/stimulus"
import { Calendar } from '@fullcalendar/core'
import elLocale from '@fullcalendar/core/locales/el'
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
//import momentPlugin from '@fullcalendar/moment'
import tippy, {roundArrow, hideAll} from 'tippy.js'
import hammer from 'hammerjs'
import 'tippy.js/dist/svg-arrow.css' 

export default class extends Controller {

  static targets = ["calendar"];

  connect() { 

		let lastClickedDateStr = "as";
		let usermode = this.data.get("usermode");
  	const calendar = new Calendar(this.calendarTarget, {  
	 		plugins: [ dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin ],
	  	themeSystem: 'pulse',
	  	//expandRows: true, not working probablt for the same reason that full height isnt
			//aspectRatio: 1,
			height: 'auto',
			locale: elLocale,
			firstDay: 1,
			navLinks: true,
			initialView: this.data.get("initialview") || 'timeGridWeekNoWeekend',
			initialDate: this.data.get("start") ||  new Date(),
			nowIndicator: true,
			allDaySlot: false,
			eventDisplay: 'block',
			slotMinTime: '08:00:00',
			slotMaxTime: '23:00:00',
			slotDuration: '00:12:00', // Makes rows longer the lower it gets
			slotLabelInterval: '00:60:00',
			eventTimeFormat: { hour12: false, hour: '2-digit', minute: '2-digit', omitZeroMinute: false },
			slotLabelFormat: { hour12: false, hour: '2-digit', minute: '2-digit', omitZeroMinute: false },
			displayEventEnd: true,
			dayHeaderFormat: { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
			headerToolbar: {
				left: 'dayGridMonth,timeGridWeek,timeGridWeekNoWeekend,timeGridDay,listWeek',
				center: 'title',
				right: 'prev,next,today',
			},
			views: {
		    dayGridMonth: {
		    	dayHeaderFormat: { weekday: 'short' },
					displayEventTime: false,
		    },
			  timeGridWeek: {
			  //  displayEventEnd: false
			  },
			  timeGridWeekNoWeekend: {
		      type: 'timeGridWeek',
		      hiddenDays: [ 0, 6 ],
		      buttonText: '5 ημέρες',
			    //displayEventEnd: false
			  },
		  },
			eventClick: function(info) {
				info.jsEvent.preventDefault(); // don't let the browser navigate
			},
			eventDidMount: function(info) {  

				// Add Mode Icon before Title
				$(info.el).find('.fc-event-title').prepend(info.event.extendedProps.confidentialIcon).prepend(info.event.extendedProps.icon); 
				$(info.el).find('.fc-list-event-title').prepend(info.event.extendedProps.list_view_icon); 

				// Tippy (for hover/click)
				if (usermode == "pv") {
					const url = info.event.extendedProps.full_details_url + ".json?action_links=true"
					info.el = tippy(info.el, {	
						content: '<i class="fa fa-solid fa-spinner fa-spin"></i>',
						onCreate(instance) {
						    instance._isFetching = false
						    instance._src = null
						    instance._error = null
						},
		  			onShow(instance) {
							if (instance._isFetching || instance._src || instance._error) {
								return
			    			}
							instance._isFetching = true
		    			fetch(url)
								.then(response => { 
									if (!response.ok) {
										throw new Error('Network Error')
									}
									return response.json()
								})
								.then(result => {
									instance.setContent(result.full_details)
									instance._src = true
								})
								.catch(error => {
									instance.setContent(`Error. ${error}`)
									instance._error = error
								})
								.finally(() => instance._isFetching = false)
						},
						allowHTML: true,
						interactive: true,
						arrow: roundArrow,
						maxWidth: 350,
						delay: [100, 200],
						trigger: 'mouseenter focus click', // click is needed so I can remove on clicking the interaction on touch devices
						touch: true,
						appendTo: () => document.body
					});
				}
			},
			dateClick: function(info) { // Needed to set the date and then doubleCLick pickes it up
				lastClickedDateStr = info.dateStr
			},
			viewDidMount: function(info) { // Passes new view to filter form so it can be maintained when page refreshes with filters. Attached DoubleCLick event for new appointments
	 			$('#filterrific_initialview').val(info.view.type) 
	 			$(info.el).bind('dblclick', function(infoClick) {
      			location.href = "/interactions/new?cdt=" + Date.parse(lastClickedDateStr)  
				})
			},
			datesSet: function(info) { 	// Passes date to filter form so we can maintain after page refresh
				//$('#filterrific_start').val(moment(info.view.currentStart).format("YYYY-MM-DD"));
				//$('#filterrific_start').val(info.view.currentStart.format("YYYY-MM-DD"))
			},
			eventSources: [
				{
					id: 'json-url',
					url: '/calendar.json?with_clinic_id=' + this.data.get("clinic") + '&with_patient_clinic_id=' + this.data.get("patient-clinic") + '&with_user_id=' + this.data.get("user") + '&with_mode=' + this.data.get("mode") + '&with_services=' + this.data.get("services") + '&with_visibility=' + this.data.get("visibility")  //+ '&initialview=' + this.info.view.type
				}
			]
		});

		
 		calendar.render();
 		this.calendar = calendar

 		// Swipe Right / LEft
 		var hammertime = new Hammer(this.calendarTarget, {
 			domEvents: true
 		});
		hammertime.on('swipeleft', function(event) {
    	hideAll(); // Tooltips
    	calendar.next();
  	});
	  hammertime.on('swiperight', function (event) {
	    hideAll(); // Tooltips
	    calendar.prev();
	  });

  }

  // When the mode of an event is updated
  refreshAllEvents() {
  	hideAll(); // Tooltips
		this.calendar.refetchEvents()
  }

  // When we use Filterrific
  filterEvents() {
  	hideAll(); // Tooltips
  	this.calendar.getEventSourceById("json-url").remove()
	 	this.calendar.addEventSource({
					id: 'json-url',
					url: '/calendar.json?with_clinic_id=' + this.data.get("clinic") + '&with_patient_clinic_id=' + this.data.get("patient-clinic") + '&with_user_id=' + this.data.get("user") + '&with_mode=' + this.data.get("mode") + '&with_services=' + this.data.get("services") + '&with_visibility=' + this.data.get("visibility")  //+ '&initialview=' + this.info.view.type
		})

  }

}
