import { Controller } from "@hotwired/stimulus"
import tippy, {roundArrow} from 'tippy.js';
import 'tippy.js/dist/svg-arrow.css';

export default class extends Controller {
	
	static targets = [ "trigger" ]
	static values = { content: String, class: { type: String, default: 'text-sm' } }

	connect() {
		this.initPopup();
	}

	// Loading Tippys from URL on hover/touch. We could do it on create if needed but more heavy for the server
	initPopup() {
		const url = this.data.get("url")
		const token = document.getElementsByName("csrf-token")[0].content

		// Gallery Tippy can override usual values using data-tippy variables
		if (this.data.get("arrow") === null) {
			var arrow_type = roundArrow
		} else { 
			var arrow_type = this.data.get("arrow")
		}
		if ($(this.data.get("append_class")).length == 0) {
			var append_class = document.body
		} else {
			var append_class = this.data.get("append_class")
		}
		
		if(this.contentValue) {
			this.triggerTarget.tippy = tippy(this.triggerTarget, {
				content: '<div class="' + this.classValue + '">' + this.contentValue + '</div>',
				allowHTML: true,
				interactive: true,
				arrow: arrow_type,
				maxWidth: 350,
				delay: [null, 400],
				trigger: 'mouseenter focus',
				touch: true,
				appendTo: () => $(append_class)[0]
			});
		} else {
			this.triggerTarget.tippy = tippy(this.triggerTarget, {
				content: '<i class="fa fa-spinner fa-spin"></i>',
				onCreate(instance) {
				    instance._isFetching = false
				    instance._src = null
				    instance._error = null
				},
	  			onShow(instance) {
					if (instance._isFetching || instance._src || instance._error) {
						return
	    			}
					instance._isFetching = true
	    			fetch(url, {
	    				headers: {
		       				"X-CSRF-Token": token
		    			}
		    		}).then(response => {
							if (!response.ok) {
								throw new Error('Network Error')
							}
							return response.json()
						})
						.then(result => {
							instance.setContent(result.full_details)
							instance._src = true
						})
						.catch(error => {
							instance.setContent(`Error. ${error}`)
							instance._error = error
						})
						.finally(() => instance._isFetching = false)
				},
				allowHTML: true,
				interactive: true,
				arrow: arrow_type,
				maxWidth: 350,
				delay: [null, 400],
				trigger: 'mouseenter focus',
				touch: true,
				appendTo: () => $(append_class)[0]
			});
		}
	}

	destroyInstance() {
		this.triggerTarget.tippy.destroy();
		this.initPopup();
	}

}