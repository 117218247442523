// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

import "@rails/actiontext"

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// Best but doesn't work on Safari 13 - Change when obsolete
import "@fortawesome/fontawesome-free/js/fontawesome"
import "@fortawesome/fontawesome-free/js/solid"
import "@fortawesome/fontawesome-free/js/regular"
FontAwesome.config.mutateApproach = 'sync'

// Local JS
import './add_jquery'
import "./long-press-event"
import "./filterrific-jquery"
import "./pagy"

// Stimulus Controllers 
import "./controllers" 

// Not sure if Actiocable and Channels are needed
import "./channels" 

import "trix"

import '@client-side-validations/client-side-validations/src'
import '@client-side-validations/simple-form/src'

// import 'signature_pad'
// var signaturePad = new SignaturePad(document.getElementById('signature-pad'), {
//   backgroundColor: 'rgba(255, 255, 255, 0)',
//   penColor: 'rgb(0, 0, 0)'
// });

document.addEventListener('turbo:load', () => {

    $("body").on('long-press keyup', function(e) {
        var key = e.which || e.key;
        if (e.type == "long-press" || (e.type == "keyup" && (key === 27 || key === "Escape" || key === "Esc"))) {
            if(e.target.localName != "select") {
                enter_public_mode()
            }
        }
    });

    // Hide Overlay when clicking outside
    $(document).mouseup(function (e) {
        var container = $("#bottom-navigation");
        if($("#addOverlay").length > 0 && !container.is(e.target) && container.has(e.target).length === 0) {
            $("#addOverlay")[0].classList.add("hidden");
            $("#addOverlayButton")[0].classList.toggle("font-semibold");
        }
    });      

   // init_signature_pad_js_stuff()

    // Track Filterrific Form Submits (on every field change) as Events in Matomo
    //GKGK Has some issues with Services and Free Text tracking
    $('#filterrific_filter').on("loadedFilterrificResults", (event) => {
        virtual_url = new URL(event.currentTarget.action)
        _paq.push(['trackEvent', 'Filter', 'Filter: ' + virtual_url.pathname.replace("/",""), document.activeElement.id.replace("filterrific_","") || "-not-set-", document.activeElement.value || "all"]);
    });

    // Track Calendar Clicks as Events in Matomo
    // Track Tippy as Content Views in Matomo ? maybe too much

});


// Track Frame Renders as Page Views in Matomo
document.addEventListener("turbo:frame-load", (event) => {
    // Make Frame ID look like a URL
    virtual_url = ((window.location.pathname + "/" + event.target.id.replace("task_","tasks/").replace("new_task","tasks/new").replace("interaction_","interactions/").replace("new_interaction","interactions/new")).replace("tasks/tasks","tasks").replace("interactions/interactions","interactions"))
    virtual_url_split = virtual_url.split("/")
    if (virtual_url_split[1] == "patients") {
        if (virtual_url_split[3] == "tasks") {
            if (virtual_url_split[4] == "new") {
                virtual_page_title = I18n["patient_s"] + " - " + I18n["task_new"]
            } else {
                virtual_page_title = I18n["patient_s"] + " - " + I18n["task_update"]
            }
        } else if (virtual_url_split[3] == "interactions") {
            if (virtual_url_split[4] == "new") {
                virtual_page_title = I18n["patient_s"] + " - " + I18n["appointment_new"]
            } else {
                virtual_page_title = I18n["patient_s"] + " - " + I18n["update_appointment"]
            }
        } else if (virtual_url_split[3] == "edit_patient") {
            virtual_page_title = I18n["patient_s"] + " - " + I18n["update_details"]
        } else if (virtual_url_split[3] == "edit_medical_history") {
            virtual_page_title = I18n["patient_s"] + " - " + I18n["update_medical_history"]
        } else if (virtual_url_split[3] == "new_medical_history") {
            virtual_page_title = I18n["patient_s"] + " - " + I18n["add_medical_history"]
        } else {
            virtual_page_title = "Patients (Unknown)"
        }
    } else if (virtual_url_split[1] == "interactions") {
        if (virtual_url_split[2] == "new") {
            virtual_page_title = I18n["interactions"] + " - " + I18n["appointment_new"]
        } else {
            virtual_page_title = I18n["interactions"] + " - " + I18n["update_appointment"]    
        }
    } else if (virtual_url_split[1] == "tasks") {
        if (virtual_url_split[2] == "new") {
            virtual_page_title = I18n["tasks"] + " - " + I18n["task_new"]
        } else {
            virtual_page_title = I18n["tasks"] + " - " + I18n["task_update"]    
        }
    } else if (virtual_url_split[1] == "today") {
        virtual_page_title = "TrueClinic - " + I18n["patients"] + " " + I18n["today"]
        if (virtual_url_split[2] == "tasks") {
            if (virtual_url_split[3] == "new") {
                virtual_page_title += " - " + I18n["task_new"]
            } else {
                virtual_page_title += " - " + I18n["task_update"]
            }
        } else if (virtual_url_split[2] == "interactions") {
            if (virtual_url_split[3] == "new") {
                virtual_page_title += " - " + I18n["appointment_new"]
            } else {
                virtual_page_title += " - " + I18n["update_appointment"]
            }
        } else if (virtual_url_split[2] == "edit_patient") {
            virtual_page_title += " - " + I18n["update_details"]
        } else {
            virtual_page_title += " - Unknown"
        }
    } else if (virtual_url_split[1] == "actions" ){
        virtual_page_title = "TrueClinic - " + I18n[virtual_url_split[1]] + " " + I18n[virtual_url_split[2]]
        if (virtual_url_split[3] == "tasks") {
            virtual_page_title += " - " + I18n["task_update"]
        } else if (virtual_url_split[3] == "interactions") {
            virtual_page_title += " - " + I18n["update_appointment"]
        } else {
            virtual_page_title += " - Unknown"
        }
    } else {
        virtual_page_title = "Unknown"
    }
    _paq.push(['setReferrerUrl', window.location.href])
    _paq.push(['setCustomUrl', virtual_url])
    _paq.push(['setDocumentTitle', virtual_page_title])
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
});

// Track Form Submits (Form - Task/Interaction/Patient - Edit/New - ID/New) as Events in Matomo
document.addEventListener("turbo:submit-end", (event) => {
    form_details = event.detail.formSubmission.fetchRequest.target.id.split('_')
    _paq.push(['trackEvent', 'Form', 'Form: ' + form_details[1], form_details[0], form_details[2] || "-new-"]);
});

