import { Controller } from "@hotwired/stimulus"
// import TabsController from 'stimulus-tabs'
// https://github.com/jwald1/stimulus-tabs

export default class extends Controller {

  static targets = [ "tabContent", "tabNavigation" ]
  static values = { "tabContentName": String, "tabFirstName": String }

  connect() {
    this.tabContentTargets.forEach((tabContent) => {
      if (tabContent.id != this.tabFirstNameValue) {
        tabContent.classList.add("hidden")
      }
    });
  } 

  showTab(event) {
    event.preventDefault()
    event.target.classList.remove("bg-white")
    event.target.classList.add("bg-gray-100")
    event.target.classList.remove("border-b-gray-200")
    event.target.classList.add("border-b-black")
    event.target.classList.remove("hover:border-b-violet-500")
    event.target.classList.add("hover:text-gray-900")
    event.target.classList.add("text-gray-900")
    this.tabNavigationTargets.forEach((tabNavigation) => {
      if (tabNavigation.id != event.params["tabContent"]) {
        tabNavigation.classList.remove("bg-gray-100")
        tabNavigation.classList.add("bg-white")
        tabNavigation.classList.remove("border-b-black")
        tabNavigation.classList.add("border-b-gray-200")
        tabNavigation.classList.remove("hover:text-gray-900")
        tabNavigation.classList.add("hover:border-b-violet-500")
        tabNavigation.classList.remove("text-gray-900")
      }
    });
    this.tabContentTargets.forEach((tabContent) => {
      if (tabContent.id == event.params["tabContent"]) {
        tabContent.classList.remove("hidden")
      } else {

        tabContent.classList.add("hidden")
      }
    });
  }
}