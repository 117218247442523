import { Controller } from '@hotwired/stimulus'
import { patch } from '@rails/request.js'

export default class extends Controller {

	static targets = ["editable", "card", "taskDeadline"]
	static values = { deadline: String, mode: Number, visible: String, updateInlineUrl: String, showPatient: String, showYear: String, isPatientPage: String }

	toggleModeField(event) {   
		let new_mode = parseInt(this.modeValue) % 3 + 1 // Move it to the next level up
    	let formData = new FormData()
    	formData.append("interaction[mode]", new_mode)
    	formData.append("interaction[show_patient]", this.showPatientValue)
    	formData.append("interaction[show_year]", this.showYearValue)
    	formData.append("interaction[is_patient_page]", this.isPatientPageValue)
	 	_paq.push(['trackEvent', 'Quick Form', 'Quick Form: interaction', "edit-mode"]);
	 	patch(this.updateInlineUrlValue, { body: formData, responseKind: "turbo-stream" })
		.then(response => {
	 	    if (response.ok) {
				// This will be picked up by Calendar
				const interaction_updated_event = new CustomEvent("eventChanged")
    			window.dispatchEvent(interaction_updated_event)    			 			 
			}
	 	})
	}

	toggleVisibilityField(event) {   
    	let new_visibility	= ((this.visibleValue == 'true') ? false : true)
    	let formData = new FormData()
    	formData.append("interaction[visible]", new_visibility)
    	formData.append("interaction[show_patient]", this.showPatientValue)
    	formData.append("interaction[show_year]", this.showYearValue)
    	formData.append("interaction[is_patient_page]", this.isPatientPageValue)
	 	_paq.push(['trackEvent', 'Quick Form', 'Quick Form: interaction', "edit-visibility"]);
	 	patch(this.updateInlineUrlValue, { body: formData, responseKind: "turbo-stream" })
	}

	clickField(event) {
	    event.preventDefault()
    
    	// Create input editor field
    	let editor = document.createElement("input")
    	editor.value = event.target.innerText
    	//editor.type = "text"
    	editor.id = "editor"
    	editor.classList.add("quick-edit-input")

		// Listen for the enter and escape keys, and stop editing when those are typed.
    	editor.addEventListener('keypress', function(event) {
		    var key = event.which || event.key;
		    if (key === 13 || key === "Escape") { // 13 is enter, 27 is escape
		    	if (this.editableTarget.innerText != editor.value) {
		        	this.sendForm(editor.value)
		        }
		        this.editableTarget.innerText = editor.value
				this.editableTarget.classList.remove("editable--hidden")
				this.editableTarget.classList.add("inline")
				editor.remove()
		    }
		}.bind(this)); 

    	// Listen for click outside for form submit
    	// Not the best solution, try click@window->quick-edit#close (https://github.com/eelcoj/stimulus-inline-edit)
		$(document).mouseup(function (event) {
			//var container = editor
			var container = $("#editor");
         	if(!container.is(event.target) && container.has(event.target).length === 0) {
		        if (this.editableTarget.innerText != editor.value) {
		        	this.sendForm(editor.value)
		        }
		        this.editableTarget.innerText = editor.value
			 	this.editableTarget.classList.remove("editable--hidden")
			 	this.editableTarget.classList.add("inline")
			 	editor.remove()
    		}
      	}.bind(this));

	    this.editableTarget.insertAdjacentElement('afterend', editor)
	    this.editableTarget.classList.add("editable--hidden")
	    this.editableTarget.classList.remove("inline")

    	// position the cursor close to where the double click event occurred, and then insert the editor element below our target.
	    let selection = window.getSelection()
		editor.setSelectionRange(selection.anchorOffset, selection.anchorOffset)
	    editor.focus()
	    
	}
 
	sendForm(new_text) {
    	let formData = new FormData()
	 	formData.append(this.data.get("field"), new_text);
	 	formData.append("task[show_patient]", this.showPatientValue)
    	formData.append("interaction[show_patient]", this.showPatientValue)
    	formData.append("interaction[show_year]", this.showYearValue)
    	formData.append("patient[is_patient_page]", "true");
	 	formData.append("interaction[is_patient_page]", this.isPatientPageValue);
	 	formData.append("task[is_patient_page]", this.isPatientPageValue);
	 	_paq.push(['trackEvent', 'Quick Form', 'Quick Form: ' +  this.data.get("field").split('[')[0], "edit-" + this.data.get("field")]);
	 	patch(this.data.get("update-url"), { body: formData, responseKind: "turbo-stream" })
    }

}