import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

	static targets = [ "form", "amkaField", "ageField", "dobField", "durationField", "searchField", "clinic", "clinicLocation", "specialist", "interactionPatientChange", "alert", "flash", "paymentOptions", "paymentOptionsQuickCash", "paymentOptionsQuickCard", "paymentOptionsQuickUnknown", "paymentSplit", "paymentSplitInput", "discardedAt", "interactionVisibilityPublic", "interactionVisibilityPrivate"]
	static values = { durationStep: Number, durationMax: Number, payments: Number }

 	
 	connect() {
 		$('form[data-client-side-validations]').enableClientSideValidations();
	}
	
	disableTurbo() {
		this.formTarget.attributes["data-turbo"].value = "false"
	}

	changedClinic() {
		this.clinicLocationTarget.value = this.clinicTarget.value
		this.changedClinicLocation()
	} 

	changedClinicLocation() {
		get(`/interactions/specialists_list?clinic=${this.clinicLocationTarget.value}&specialist_selected=${this.specialistTarget.value}`, { responseKind: "turbo-stream" })
	} 

	changedSpecialist() {
		get(`/interactions/clinic_locations_list?specialist=${this.specialistTarget.value}&clinic_selected=${this.clinicLocationTarget.value}`, { responseKind: "turbo-stream" })
	} 

	// If price is a number then enable the Quick Inputs as long as there are no existing payments, otherwise disable
	priceInput(event) {
		if (!isNaN(event.target.value) && event.target.value != "" && this.paymentsValue == 0) {
			this.paymentOptionsQuickCashTarget.disabled = ""
			this.paymentOptionsQuickCardTarget.disabled = ""
			this.paymentOptionsQuickUnknownTarget.disabled = ""
		} else {
			this.paymentOptionsQuickCashTarget.disabled = "disabled"
			this.paymentOptionsQuickCardTarget.disabled = "disabled"
			this.paymentOptionsQuickUnknownTarget.disabled = "disabled"		
		}
	}

	amkaInput(event) {
		if (!isNaN(event.target.value) && event.target.value.length > 5 && !this.dobFieldTarget.value) {
			if (parseInt(event.target.value.slice(4, 6)) > 23) {
				var year = 1900 + parseInt(event.target.value.slice(4, 6))
			} else {
				var year = 2000 + parseInt(event.target.value.slice(4, 6))
			}
			this.dobFieldTarget.value =  event.target.value.slice(0, 2) + "-" + event.target.value.slice(2, 4) + "-" + year.toString()
			const dob_updated_event = new CustomEvent("dobUpdated", { detail: { value: this.dobFieldTarget.value }} )
    		window.dispatchEvent(dob_updated_event)    	
		}
	}

	dobInput(event) {
		var today = new Date();
    	var minDate = new Date();
    	var dob_obj = event.detail || event.target // Depending if we are coming from AMKA->CustomEVent or from Form->DOB
    	minDate.setFullYear(minDate.getFullYear() - 120);
    	if (dob_obj.value.length == 10) {
            var dob_selected = new Date(dob_obj.value.slice(0,10).split('-').reverse().join('-'))
            var age_estimated = Math.round((today - dob_selected)/(1000*60*60*24*365))
            this.ageFieldTarget.disabled = true
            this.ageFieldTarget.value = age_estimated     
            $("#patient_date_of_birth_estimated").val(false);     
        } else {
            this.ageFieldTarget.disabled = false     
        }
	}

	ageInput(event) {
        if(parseInt(event.target.value)) {
            $("#patient_date_of_birth_estimated").val(true); 
        } else {
            $("#patient_date_of_birth_estimated").val(false);
        }
	}

	quickPaymentSelected(event) {
		if (event.target.value == "1") { // Cash
			this.paymentSplitTarget.classList.add("hidden")
		} else if (event.target.value == "2") { // Card
			this.paymentSplitTarget.classList.add("hidden")
			if (!this.interactionVisibilityPublicTarget.checked) {
				this.interactionVisibilityPublicTarget.checked = true
				this.alertTarget.classList.remove("hidden")
			}
		} else if (event.target.value == "4") { // Unknown
			this.paymentSplitTarget.classList.add("hidden")
		} else if (event.target.value == "3") { // Mixed
			this.paymentSplitTarget.classList.remove("hidden")
		}
	}

	splitPaymentMethodSelected(event) {
		if (event.target.value == "2" && !this.interactionVisibilityPublicTarget.checked) { 
			this.interactionVisibilityPublicTarget.checked = true
			this.alertTarget.classList.remove("hidden")
		}
	}

	discardPayment(event) {
		this.discardedAtTarget.value = new Date()
	}

	closeFlash(event) {
		event.preventDefault() // Stops the button from submitting form
		this.flashTarget.classList.add("hidden")
	}

	autoCloseFlash() {
		this.flashTarget.classList.add("hidden")
	}
		//  X to clear the input field
	clearField(event) {
		this.searchFieldTarget.value = ""
		this.formTarget.requestSubmit()
	}

	increment() {
    	this.durationFieldTarget.value = Math.min(parseInt(this.durationFieldTarget.value) + this.durationStepValue, this.durationMaxValue)
  	}
	
	decrement() {
    	this.durationFieldTarget.value = Math.max(parseInt(this.durationFieldTarget.value) - this.durationStepValue, 0)
  	}

  	toggleInteractionPatientChangeField(event) {
  		this.interactionPatientChangeTargets[0].classList.toggle("hidden")
  		this.interactionPatientChangeTargets[1].classList.toggle("hidden")
  	}

}
