import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default class extends Controller {
	
	static targets = ["gallery"]

	connect() {

		const lightbox = new PhotoSwipeLightbox({
	        //modal: false, // Potential to constrain it into a div, rather than full window
	        gallery: '#gallery',
	        children: 'a',
	        pswpModule: PhotoSwipe,
	        // appendToEl: document.querySelector('#heregoes'),
	        loop: false,
	        tapAction: false, // In order to use it for tippy close
	        closeTitle: 'Κλείσιμο',
	        zoomTitle: 'Zoom',
	        arrowPrevTitle: 'Προηγούμενη',
	        arrowNextTitle: 'Επόμενη'
	    });

	    lightbox.on('uiRegister', function() {
	    
	        lightbox.pswp.ui.registerElement({
	            name: 'info-button',
	            order: 6,
	            isButton: true,
	            position: 'bar',
	            title: '',
	            html: '<i class="fa fa-info"></i>',
	            onInit: (el, pwsp) => {
	                lightbox.pswp.on('change', () => {
	                    if (lightbox.pswp.currSlide.data.element.dataset.interactionid ) {
	                    	el.innerHTML = '<div data-controller="tippy" data-action="galleryChanged@window->tippy#destroyInstance eventChanged-' + lightbox.pswp.currSlide.data.element.dataset.interactionid + '@window->tippy#destroyInstance" data-tippy-target="trigger" data-tippy-url="' + lightbox.pswp.currSlide.data.element.dataset.interactionurl + '" data-tippy-arrow=false data-tippy-append_class=".pswp" class="inline text-violet-700 px-2 cursor-pointer"><i class="fa fa-info"></i></div>'
	                	} else {
	                		el.innerHTML = ""
	                	}
	                });             
	            }
	        });

	        // lightbox.pswp.ui.registerElement({
	        //     name: 'tags-button',
	        //     order: 7,
	        //     isButton: true,
	        //     position: 'bar',
	        //     title: 'Show Tags',
	        //     html: '<i class="fa fa-tags text-gray-500"></i>',
	        // });

	        lightbox.pswp.ui.registerElement({
	            name: 'download-button',
	            order: 8,
	            isButton: true,
	            position: 'bar',
	            title: 'Download',
	            html: '<i class="fa fa-download"></i>',
	            onClick: (event, el, pswp) => {
					var link = document.createElement('a')
					var filePath = lightbox.pswp.currSlide.data.element.dataset.pswpDownloadurl
        			link.href = filePath
        			link.download = filePath.substr(filePath.lastIndexOf('/') + 1)
        			link.click()
	            }
	        });

	        lightbox.pswp.ui.registerElement({
	            name: 'custom-caption',
	            order: 9,
	            isButton: false,
	            appendTo: 'wrapper',
	            onInit: (el, pswp) => {
	              lightbox.pswp.on('change', () => {
	                const currSlideElement = lightbox.pswp.currSlide.data.element;
	                let captionHTML = '';
	                if (currSlideElement) {
	                    captionHTML = currSlideElement.querySelector('img').getAttribute('alt');
	                }
	                el.innerHTML = captionHTML || '';
	              });
	            }
	        });

	    });

	    lightbox.on('slideActivate', function() {
   			// Event to be picked up from tippy so that it hides itself as we have moved to new slide 
   			const gallery_slide_changed_interaction_close = new CustomEvent("galleryChanged")
			window.dispatchEvent(gallery_slide_changed_interaction_close) 
	    });

	    lightbox.init();
	    this.lightbox = lightbox

	}

}
