import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

export default class extends Controller {
    static targets = [ "hideCompletedLink", "showCompletedLink", "showLinkedInteractions", "hideLinkedInteractions", "hideableInteractionCard", "card"]
	// static values = { linked: String }

	toggleCompleted(event) {
		event.preventDefault()
		this.hideCompletedLinkTarget.classList.toggle("hidden")
		this.showCompletedLinkTarget.classList.toggle("hidden")
		if (this.showCompletedLinkTarget.classList.value == 'hidden') {
			const task_event = new CustomEvent("toggleTaskCard", { detail: { value: "show" } } )
			window.dispatchEvent(task_event) 
		} else {
			const task_event = new CustomEvent("toggleTaskCard", { detail: { value: "hide" } } )	
			window.dispatchEvent(task_event) 
		}
	}

	toggleLinkedInteractions(event) {
		event.preventDefault()
		this.showLinkedInteractionsTarget.classList.toggle("hidden")
		this.hideLinkedInteractionsTarget.classList.toggle("hidden")
  		const interaction_event = new CustomEvent("toggleInteractionCard")
		window.dispatchEvent(interaction_event) 
	}

	toggleInteractionCard(event) {
		this.hideableInteractionCardTarget.classList.toggle("hidden")
	}
	
	highlightInteractions(event) {
		event.preventDefault()
  		const highlight_event = new CustomEvent("highlightInteractionCard", { detail: { parent_id: event.params.id }})
		window.dispatchEvent(highlight_event) 
	}

	highlightInteractionCard(event) {
		if (this.cardTarget.id == event.detail.parent_id) {
    		this.cardTarget.classList.toggle("border-white")
    		this.cardTarget.classList.toggle("border-gray-400")
		}
	}	
}